import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import Component2 from '../../../assets/images/blogs/Component2.png';
import guitar1 from '../../../assets/images/blogs/guitar1.png';
import guitar2 from '../../../assets/images/blogs/guitar2.png';

const Guitar = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={Component2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									The guitar is a string instrument that is typically played
									with fingers or a pick. It is one of the most popular
									instruments in the world, and the second most popular
									instrument after the piano. The sound of the guitar can be
									heard in many types of music including classical music, rock
									and roll, blues, and jazz. The guitar was invented by the
									Spaniard, Antonio de Torres Jurado, in 1833. Guitars come in a
									variety of shapes, sizes, and styles. They are often used to
									create music. There are three main types of guitars: acoustic,
									electric and classical. Acoustic guitars are usually used in
									folk music and rock music while electric guitars are used in
									heavy metal music. Classical guitars are used for classical
									pieces such as Mozart's Requiem Mass in D Minor. It's
									recommended for beginners to learn acoustic guitar.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={guitar1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">Ways to practice the guitar:</h2>
										<p>
											There are a number of ways to learn guitar. It is
											important to start with the basics and work your way up.
											Once you are comfortable with chords, scales, and
											strumming, try fingerpicking and other techniques. <br />{' '}
											The guitar is a difficult instrument to learn but not
											impossible. Patience and dedication are key to success{' '}
											<br />
											-Practicing with another person can be very helpful. It
											helps with improv skills, it's great for learning new
											songs, and it could help you improve your guitar
											technique. <br /> - Practicing recorded music helps for a
											variety of reasons. It will help improve your rhythm and
											timing, as you can focus on that without following the
											sheet music.
											<br /> - Learning guitar is a lot easier when you're
											taught by someone who has experience.
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning how to play the guitar has never been easier!
											There are plenty of free resources available on internet
											such as practice videos or even written tutorials, but the
											best effective way is to follow a structured lesson from
											an expert trainer. Take5 Music offers structured learning
											opportunities through experienced and qualified trainers
											who provide live online classes. All you need is a
											computer or laptop with internet access and a few hours of
											spare time each day.
										</p>
									</div>
									<img
										src={guitar2}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Guitar;
