import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import hindustani2 from '../../../assets/images/blogs/hindustani2.png';
import hindustani from '../../../assets/images/blogs/hindustani.png';
import hindustani1 from '../../../assets/images/blogs/hindustani1.png';

const Hindustani = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={hindustani2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container" style={{marginTop: '25px'}}>
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									Hindustani classical vocals is a genre of Indian Classical
									music which is primarily vocal-based. It is the main tradition
									of North Indian Classical music, and it has been in existence
									for over 500 years. The most popular form of Hindustani
									classical vocals today is Khayal, which means "imagination."
									The singer engages in a dialogue with the audience and recites
									poetry in a melodic manner. The lyrics are often in Hindi or
									Sanskrit. Hindustani classical music is considered to be one
									of the oldest musical forms in the world. The history of
									Hindustani classical vocals can be traced back to the 8th
									century, which was when it was first developed. Hindustani
									classical vocals were developed by combining elements from
									other Indian music forms and styles, including Carnatic and
									Dhrupad.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={hindustani}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">
											Ways to practiceHindustani Classical Vocals:
										</h2>
										<p>
											The best way to practice Hindustani classical vocals is to
											listen to recordings of the songs and analyse them for
											various aspects such as rhythm, melody, and lyrics. This
											will help you get a better understanding of the nuances of
											this form of singing. In this section, we will talk about
											three ways that one can practice Hindustani classical
											vocals:
											<br /> - Learn the basics of music theory
											<br /> - Learn how to read and write Hindusthani Music.
											<br /> - Practice vocal exercises
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning Hindustani classical vocals is not that easy. You
											need to have a good knowledge of music theory. There are
											plenty of free resources available on internet such as
											practice videos or even written tutorials, but the best
											effective way is to follow a structured lesson from an
											expert trainer. Take5 Music offers structured learning
											opportunities through experienced and qualified trainers
											who provide live online classes. All you need is a
											computer or laptop with internet access and a few hours of
											spare time each day.
										</p>
									</div>
									<img
										src={hindustani1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Hindustani;
