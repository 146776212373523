import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import carnatic2 from '../../../assets/images/blogs/carnatic2.png';
import carnatic from '../../../assets/images/blogs/carnatic.png';
import carnatic1 from '../../../assets/images/blogs/carnatic1.png';

const Carnatic = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={carnatic2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container" style={{marginTop: '25px'}}>
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									Carnatic vocal is a genre of Indian classical music that is
									traditionally sung without any instrumental accompaniment. It
									consists of melodic modes called ragas, which are based on
									classical Indian musical systems called thaats. The lyrics are
									typically in a regional language, usually Kannada, Telugu or
									Tamil, while they sometimes include Sanskrit as well. The
									history of Carnatic vocals is quite long and there are many
									different styles that have emerged over time. The earliest
									style of Carnatic vocals was called svaravalis, with these
									songs being composed in the Sanskrit language. This style of
									singing was later followed by the devotional songs called
									kirtanas and which were composed in the Telugu language.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={carnatic}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">Ways to practice Carnatic vocals:</h2>
										<p>
											-Practice is the key to success. There are many ways to
											practice Carnatic vocals and one can choose whichever
											suits them best. -One of the easiest ways is to learn from
											a teacher who has mastered this art form.
											<br /> -Practicing with a metronome is important. This
											helps the person playing stay on tempo while practising.
											<br /> -Practising with the tanpura(tambura)
											<br /> -Every singer should practice their vocals in front
											of the mirror because it's easier to spot potential flaws
											by watching your own mouth and face movements.
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning Carnatic vocals is not an easy task. It requires
											a lot of practice and dedication. But with the right
											guidance, you can learn it in no time. There are plenty of
											free resources available on internet such as practice
											videos or even written tutorials. Still, the best
											effective way is to find a good teacher who can teach you
											the basics and help you understand the underlying theory
											behind it. Take5 Music offers structured learning
											opportunities through experienced and qualified teachers
											who provide live online classes. All you need is a
											computer or laptop with internet access and a few hours of
											spare time each day.
										</p>
									</div>
									<img
										src={carnatic1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Carnatic;
