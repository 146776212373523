import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Api from '../services/Api';
import {authContext} from '../auth/AuthContext';
import './main.css';
import ProcessTake5 from './common/ProcessTake5';

const LoginByadmin = (props) => {
	const {stid} = useParams();

	const {setAuthData} = useContext(authContext);

	//function LoginByadmin(stid){

	useEffect(() => {
		Api.studentloginbyadmin(stid).then((response) => {
			if (response.data.success) {
				setAuthData(response.data.data);
				window.location.href = '/student-dashboard';
				/*  alert.show(response.data.message, {
                        timeout: 1000, // custom timeout just for this one alert
                        type: 'success',
                        onOpen: () => {
                           
                         // 
                        },  // callback that will be executed after this alert open
                        onClose: () => {
                            //return <Redirect to='/' />
                            window.location.href = '/student-dashboard';
                         // 
                        } 
                        
                    });*/
			}
		});
	});
	//LoginByadmin(stid);

	return (
		<div class="auth_main_wrapper">
			<div class="auth_login_wrapper">
				<div class="auth_detail">
					<div class="auth_logo">
						<a href="/">
							<img src="assets/images/logo.png" class="img-fluid" alt="" />
						</a>
					</div>

					<h1>Login</h1>
				</div>
				<ProcessTake5 />
			</div>
		</div>
	);
};
export default LoginByadmin;
