import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import flute2 from '../../../assets/images/blogs/flute2.png';
import flute from '../../../assets/images/blogs/flute.png';
import flute1 from '../../../assets/images/blogs/flute1.png';

const Flute = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={flute2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									The flute is a musical instrument that has been around for
									centuries. It has been used in many different cultures and
									traditions, and in many different types of music. The flute is
									one of the oldest known musical instruments. The word "flute"
									comes from the Latin word "flautus," which means "to blow."
									The flute is a woodwind instrument that produces sound by
									blowing air across the mouth of the instrument. The player
									blows into the mouthpiece, which acts as a resonator and then
									modulates his or her breath to produce musical tones. There
									are three main types of flute transverse flute, recorder, and
									pan flute - A transverse flute is a musical instrument which
									is held sideways and blown across the mouthpiece from the
									side. A recorder is a woodwind instrument that has a
									cylindrical bore and holes on its front face for the fingers
									to block and unblock (stop) the airflow. The pan flutes are
									instruments that have a sound chamber called a resonator,
									which produces sound by forcing air across an open-ended tube,
									or duct, typically made from metal or wood.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={flute}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">Ways to practice the Flute:</h2>
										<p>
											There are many ways to practice the flute. Practising the
											flute on your own is also a great way to improve your
											skills. One way is to use a metronome. The metronome can
											help you keep a steady tempo and rhythm. Practice daily
											for about 30 minutes each day. You can practice scales,
											and arpeggios, Play songs that you know well or songs in
											different keys. Practice fingerings and articulations.
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning how to play the flute can be a challenging task.
											There are many things that you need to learn, such as the
											different notes, how to breathe, and how to make the
											sound. There are plenty of free resources available on
											internet such as practice videos or even written
											tutorials, but the best effective way is to follow a
											structured lesson from an expert trainer. Take5 Music
											offers structured learning opportunities through
											experienced and qualified trainers who provide live online
											classes. All you need is a computer or laptop with
											internet access and a few hours of spare time each day.
										</p>
									</div>
									<img
										src={flute1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Flute;
