import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import tabla2 from '../../../assets/images/blogs/tabla2.png';
import tabla from '../../../assets/images/blogs/tabla.png';
import tabla1 from '../../../assets/images/blogs/tabla1.png';

const Tabla = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={tabla2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									Tabla is a percussion instrument that originates from India.
									The tabla is also known as the pakhawaj, tabl or dholak. The
									tabla consists of a pair of drums. The left drum is called
									dahina/dayan and it produces a bass sound and the right drum
									is called baya,/bayan which produces a treble sound. It is
									played with both hands on the drum heads, and it has a range
									from low and loud to high and soft. It is an important
									instrument in Hindustani classical music and is used in many
									other types of world music. To learn the tabla, one needs to
									have a good understanding of the various techniques, rhythms
									and taals (rhythmic cycles).A person who plays the tabla is
									called a tablist. Tablists are usually associated with
									classical music from the Indian subcontinent, including
									Hindustani music as well as Carnatic music.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={tabla}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">Ways to practice the Tabla:</h2>
										<p>
											There are many ways one can practice the tabla. The most
											important thing is to find a method that suits you best.
											The most common way is by playing simple exercises, called
											"taals" or "tala" for short. The following are some
											different ways in which one can practice the tabla.
											<br /> -Learn how to play different rhythms,
											<br /> -Practice different patterns on the tabla,
											-Memorize the notes on the tabla, -Practice with a
											metronome,
											<br /> -Playing taals on your own, -Playing taals with a
											teacher,
											<br /> -Learning to play riffs and licks (a phrase or
											melody played in succession),
											<br /> -Learning compositions (a long piece of music),
											learning folk songs.
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning tabla is not an easy task. It takes a lot of
											time, effort and patience to learn the art. There are
											plenty of free resources available on internet such as
											practice videos or even written tutorials, but the best
											effective way is to follow a structured lesson from an
											expert trainer. Take5 Music offers structured learning
											opportunities through experienced and qualified trainers
											who provide live online classes. All you need is a
											computer or laptop with internet access and a few hours of
											spare time each day.
										</p>
									</div>
									<img
										src={tabla1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Tabla;
