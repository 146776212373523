/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react';
import {Teacher_sidebar} from '../../components';
import TeacherVideoCall from './TeacherVideoCall';
import {useAlert} from 'react-alert';

const teacherData = JSON.parse(localStorage.getItem('teacher'));

export default function TeacherDashboard() {
	console.log('====================================');
	console.log(teacherData);
	console.log('====================================');
	const alert = useAlert();
	const onCopy = () => {
		alert.show('Class ID copied', {
			timeout: 5000, // custom timeout just for this one alert
			type: 'success',
			onOpen: () => {
				navigator.clipboard.writeText(teacherData.id);
			}, // callback that will be executed after this alert open
			onClose: () => {}, // callback that will be executed after this alert is removed
		});
	};
	return (
		<div className="take_admin_main_wrapper">
			<Teacher_sidebar />

			<div className="take_top_header">
				<h1 className="take_title text-center h1">Teacher Dashboard</h1>
				<p className="take_title p">Teacher ID: {teacherData.id} </p>
				<p className="take_title p">
					Teacher Name: {teacherData.name.toUpperCase()}{' '}
				</p>
				<p className="take_title p">
					Instrument: {teacherData.instruments[0]}{' '}
				</p>
				<p
					className="take_title p"
					id="myInput"
					style={{
						justifyContent: 'flex-start',
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'row',
					}}>
					Class ID: {teacherData.id}{' '}
					<button
						onClick={() => onCopy()}
						style={{
							background: '#f68b00',
							border: 'none',
							color: '#fff',
							cursor: 'pointer',
							padding: '5px',
							borderRadius: '5px',
							marginLeft: '10px',
							fontSize: '12px',
						}}>
						Copy Class ID
					</button>{' '}
				</p>

				<div className="take_body">
					<a
						href="javascript:;"
						onClick={() => {
							// alert("No Upcoming Class");
							let url =
								window.location.protocol +
								'//' +
								window.location.host +
								`/class/${teacherData.id}`;
							window.open(url, '_blank');
						}}
						className="take_btn">
						{' '}
						Start the Class
					</a>
				</div>
			</div>
		</div>
	);
}
