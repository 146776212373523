import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import drum2 from '../../../assets/images/blogs/drum2.png';
import drum from '../../../assets/images/blogs/drum.png';
import drum1 from '../../../assets/images/blogs/drum1.png';

const Drums = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={drum2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									The drum is one of the oldest musical instruments, and it has
									a very long history. It is also one of the most diverse
									instruments, with many different types. The drums vary in
									size, shape, material and use but they all have one thing in
									common - they produce sound by striking them with sticks or
									hands to produce a rhythmic pattern or beat. The types of
									drums are categorised into three categories: membranophones,
									idiophones, and chordophones. Membranophones are instruments
									that produce sound by the vibration of a stretched membrane.
									Idiophones are instruments that produce sound by being struck
									or shaken. Chordophones are instruments that produce sound
									from the vibration of strings.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={drum}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">Ways to practice the Drums:</h2>
										<p>
											Drumming is an art that requires a lot of practice and
											patience. You can't just pick up a drum and start playing
											it. There are many important things you need to know
											before starting to play the drums such as how to tune the
											drums, how to hold the sticks, how to read drum notation
											and what is the proper way to sit on a drum stool. You can
											also use some techniques like finger exercises or
											practising your rudiments (drumming patterns) in order to
											improve your technique. There are many different ways to
											practice drumming. One way is to use a practice pad.
											Practice pads come in many different sizes, so they can be
											used by beginners or advanced drummers. Another way to
											practice the drums is using a metronome or some other kind
											of device that helps you keep time with the beat you are
											playing.
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning to play the drums can be difficult because there
											are different parts of the drum and each one has its own
											sound. It's important to find a good teacher who can show
											you the basics, and help you learn the most effective
											strokes. There are plenty of free resources available on
											internet such as practice videos or even written
											tutorials, but the best effective way is to follow a
											structured lesson from an expert trainer. Take5 Music
											offers structured learning opportunities through
											experienced and qualified trainers who provide live online
											classes. All you need is a computer or laptop with
											internet access and a few hours of spare time each day.
										</p>
									</div>
									<img
										src={drum1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Drums;
