import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import sarangi2 from '../../../assets/images/blogs/sarangi2.png';
import sarangi from '../../../assets/images/blogs/sarangi.png';
import sarangi1 from '../../../assets/images/blogs/sarangi1.png';

const Sarangi = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={sarangi2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container" style={{marginTop: '25px'}}>
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									The sarangi is an Indian stringed instrument that is a part of
									the lute family. It has been used in many different genres of
									music, including Hindustani music, Carnatic music, as well as
									Western classical music. It was first invented in the 16th
									century in Northern India by Amir Khusrau. It was derived from
									the rubab, an instrument popular in Persia at that time. The
									word “sarangi” means “a hundred colours” or “variegated”,
									which reflects the wide range of musical styles it can be
									played in. The typical repertoire of sarangi players is
									traditionally closely related to vocal music. There are many
									types of Sarangi, but the most common ones are the bansuri and
									sursringar. The bansuri is made from bamboo or coconut shells
									while the sursringar is made from wood carved into an oval
									shape to make it resonate better. There are two types of
									sarangi Hindustani: - Sarangi Sargam: This type of sarangi
									Hindustani has three strings and it's used to play the melody.
									- Sarangi tala: This type of sarangi Hindustani has four
									strings and it's used to play rhythm.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={sarangi}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">Ways to practice the Sarangi:</h2>
										<p>
											Practising the sarangi is a great way to learn Hindustani
											classical music. It requires a lot of dedication and
											patience to get the perfect sound. One of the most
											important things is to keep your instrument in tune. If
											you are playing the sarangi, you need to work on your
											left-hand technique and right-hand technique as well. If
											you are new to this instrument, it is best to start from
											the basics and learn how to play it using just your
											fingers. Practice playing at different speeds, from slow
											to fast and then back again. There is no shortcut to
											learning sarangi the only way is to practice, practice and
											practice some more.
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning Sarangi is not an easy task. One needs to be
											aware of the many nuances and intricacies of the
											instrument in order to master it. There are plenty of free
											resources available on internet such as practice videos or
											even written tutorials, but the best effective way is to
											follow a structured lesson from an expert trainer. Take5
											Music offers structured learning opportunities through
											experienced and qualified trainers who provide live online
											classes. All you need is a computer or laptop with
											internet access and a few hours of spare time each day.
										</p>
									</div>
									<img
										src={sarangi1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Sarangi;
