const TeacherApi = [
	{
		id: 'T5T-0038',
		name: 'Sravya Bharati',
		image:
			'http://take5music.in/take5app1//assets/1667549264591Screenshot 2022-11-04 at 1.13.50 PM.png',
		about: 'Carnatic music trainer',
		email: 'kaantinalina@gmail.com',
		status: 'active',
		price: '',
		instruments: ['Carnatic Vocals'],
	},
	{
		id: 'T5T-0036',
		name: 'Dhruva Bharadwaj',
		image:
			'http://take5music.in/take5app1//assets/1664350775416WhatsApp Image 2022-09-27 at 9.54.24 PM.jpeg',
		about: 'Very Friendly and Liked minded trainer!',
		email: 'dhruvanateshdp@gmail.com',
		status: 'active',
		price: '',
		instruments: ['Carnatic Vocals'],
	},
	{
		id: 'T5T-0035',
		name: 'Diju Acharya',
		image:
			'http://take5music.in/take5app1//assets/1664255043120254342388_148880714366979_4720590052978413593_n.jpg',
		about:
			'Violin Professional!\nGold medalist for the eastern zone. \nThird highest score in India for grade 5 from ABRSM.\nB High grade in Calcutta Cine Musicians Association.\nB High in All India Radio with violin.\nPlayed in various films and YouTube videos so far.\n',
		email: 'meetdacharya@gmail.com',
		status: 'active',
		price: '',
		instruments: ['Violin'],
	},
	{
		id: 'T5T-0027',
		name: 'Jestar ',
		image:
			'http://take5music.in/take5app1//assets/1643797692693PSX_20211223_115443.jpg',
		about: 'Friendly Instructor, Amazing Singer!',
		email: 'jestargj@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Western Vocals'],
	},
	{
		id: 'T5T-0011',
		name: 'Sarang Gupta',
		image:
			'http://take5music.in/take5app1//assets/1664256541505288353057_1413664909146893_8217319309224447494_n.jpeg',
		about: 'Flute Professional',
		email: 'emailsaranggupta@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Flute'],
	},
	{
		id: 'T5T-0010',
		name: 'Tathagata',
		image: 'http://take5music.in/take5app1//assets/16419848423091.jpeg',
		about: 'Guitar Professional',
		email: 'tathagata19@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Guitar'],
	},
	{
		id: 'T5T-0009',
		name: 'Laxman',
		image:
			'https://take5music.in/take5app1/assets/1624456806596image_6483441.JPG',
		about: 'Well experienced Guitar Teacher',
		email: 'laxmanchary4@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Guitar'],
	},
	{
		id: 'T5T-0008',
		name: 'Ayush Dwivedi ',
		image:
			'http://take5music.in/take5app1/assets/1629368635509FB_IMG_1625469179569.jpeg',
		about: 'Hi ! \nnow the edit is working',
		email: 'dwivediayush.work@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Guitar'],
	},
	{
		id: 'T5T-0007',
		name: 'Apurva Michelle',
		image: 'http://take5music.in/take5app1//assets/1638183795234final.png',
		about: 'Western Vocals ',
		email: 'michelleapoorva@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Western Vocals'],
	},
	{
		id: 'T5T-0006',
		name: 'Sivani',
		image:
			'https://take5music.in/take5app1/assets/1623754954270IMG-20200929-WA0012.jpg',
		about: 'Carnatic Vocal Teacher',
		email: 'chaluvagalisivani@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Carnatic Vocals'],
	},
	{
		id: 'T5T-0005',
		name: 'Vikram',
		image:
			'https://take5music.in/take5app1/assets/1624344985885WhatsApp Image 2021-06-22 at 12.26.04 PM.jpeg',
		about: 'Western/Carnatic',
		email: 'vikram.tirupati@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Violin'],
	},
	{
		id: 'T5T-0004',
		name: 'Sarah',
		image:
			'http://take5music.in/take5app1//assets/1635237017562WhatsApp Image 2021-10-22 at 9.36.37 PM.jpeg',
		about: 'Piano Professional',
		email: 'sarahnainsukh@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Piano'],
	},
	{
		id: 'T5T-0003',
		name: 'Nakul Raikhelkar',
		image:
			'http://take5music.in/take5app1//assets/16317725457871630942740846.jpeg',
		about: 'Piano & Keys',
		email: 'raikhelkarnaky@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Piano'],
	},
	{
		id: 'T5T-0002',
		name: 'Ravi Singh',
		image:
			'https://take5music.in/take5app1/assets/16243445987561623776151246.jpg',
		about:
			'Very friendly teacher with good knowledge of teaching young children ',
		email: 'singravisingh17@gmail.com',
		status: 'active',
		price: '500',
		instruments: ['Piano'],
	},
	{
		id: 'T5T-0001',
		name: 'Srikanth',
		image:
			'https://take5music.in/take5app1/assets/1624456558290Srikanth edited.jpg',
		about: 'Like Minded Easy going trainer',
		email: 'msrikanth.teja@gmail.com',
		status: 'active',
		price: '300',
		instruments: ['Piano'],
	},
	{
		id: 'T5T-harshit',
		name: 'HARSHIT CHARI',
		image:
			'https://take5music.in/take5app1/assets/1624456558290Srikanth edited.jpg',
		about: 'Like Minded Easy going trainer',
		email: 'harshit.josh+t1@gmail.com',
		status: 'active',
		price: '300',
		instruments: ['Piano'],
	},
	{
		id: 'T5T-RAJEEV',
		name: 'RAJEEV BUKKA',
		image:
			'https://take5music.in/take5app1/assets/1624456558290Srikanth edited.jpg',
		about: 'Like Minded Easy going trainer',
		email: 'rajeevbukka91@gmail.com',
		status: 'active',
		price: '300',
		instruments: ['Piano'],
	},
	{
		id: 'T5T-SUDHAB',
		name: 'SUDHA B',
		image:
			'https://take5music.in/take5app1/assets/1624456558290Srikanth edited.jpg',
		about: 'Like Minded Easy going trainer',
		email: 'sudhaabhujel@gmail.com',
		status: 'active',
		price: '300',
		instruments: ['Piano'],
	},
];
export default TeacherApi;
