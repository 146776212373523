import axios from 'axios';
import config from '../assets/constants/environment';
//Get parameters from url by key
function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		if (pair[0] === variable) {
			return pair[1];
		}
	}
	return false;
}

// Create instance called instance
const nodeUrl = config.nodeEnv;

const siteUrlNews = 'https://news.take5music.in/api/';

const instance = axios.create({
	baseURL: nodeUrl,
	headers: {
		'content-type': 'application/json',
		'Client-Service': 'frontend-client',
		'Auth-Key': 'b6a6af7226f48e3a75e9ecff44a212b92141',
	},
});

const instance1 = axios.create({
	baseURL: siteUrlNews,
	headers: {
		'content-type': 'application/json',
		'Client-Service': 'frontend-client',
		'Auth-Key': 'b6a6af7226f48e3a75e9ecff44a212b92141',
	},
});

export default {
	registerUserWithMail: (params) => {
		return axios.post(nodeUrl + 'registerUserMobile', {
			formdata: params,
		});
	},

	registerWorkshop: (params) => {
		return axios.post(nodeUrl + 'saveWorkshopRegistration', {
			formdata: params,
		});
	},

	verifyRegisterOTP: (userid, otp) => {
		return axios.post(nodeUrl + 'verifyRegisterOTP/' + userid + '/' + otp, {
			formdata: '',
		});
	},

	verifyForgotOTP: (userid, otp) => {
		return axios.post(nodeUrl + 'verifyForgotOTP/' + userid + '/' + otp, {
			formdata: '',
		});
	},

	forgotpasswordUpdate: (params, user_id) => {
		return axios.post(nodeUrl + 'forgotpasswordUpdate/' + user_id, {
			formdata: params,
		});
	},

	sendDemoOTP: (user_id, country) => {
		return axios.post(nodeUrl + 'sendDemoOTP/' + user_id + '/' + country, {
			formdata: '',
		});
	},

	loginWithEmail: (params) => {
		return axios.post(nodeUrl + 'loginWithEmail', {
			formdata: params,
		});
	},

	contactus: (params) => {
		return axios.post(nodeUrl + 'contactus', {
			formdata: params,
		});
	},

	getnews: () =>
		instance1({
			method: 'GET',
			url: 'getNews',
		}),

	classesTimeAvailable: () =>
		instance({
			method: 'GET',
			url: 'classesTimeAvailable',
		}),

	classesTimeAvailableAndDays: (user_id) =>
		instance({
			method: 'GET',
			url: 'classesTimeAvailableAndDays/' + user_id,
		}),

	getGroupMember: (userId) => {
		return axios.get(nodeUrl + 'getGroupMember/' + userId);
	},

	loginWithMobileAndEmail: (params) => {
		return axios.post(nodeUrl + 'loginWithMobileAndEmail', {
			formdata: params,
		});
	},

	submitStartCourseAPIDemo: (params) => {
		return axios.post(nodeUrl + 'submitStartCourseAPIDemo', {
			formdata: params,
		});
	},

	registerUserWithMobile: (params) => {
		return axios.post(nodeUrl + 'registerUserWithMobile', {
			formdata: params,
		});
	},

	UpdateProfile: (params) => {
		return axios.post(nodeUrl + 'UpdateProfile', {
			formdata: params,
		});
	},

	getTeacherList: () =>
		instance({
			method: 'GET',
			url: 'getTeacherList',
		}),

	getInstrument: () =>
		instance({
			method: 'GET',
			url: 'getInstrument',
		}),

	getBlogsData: () =>
		instance({
			method: 'GET',
			url: 'getBlogsData',
		}),

	getDemoClass: (user_id) =>
		instance({
			method: 'GET',
			url: 'getDemoClass/' + user_id,
		}),

	getTodayClass: (user_id) =>
		instance({
			method: 'GET',
			url: 'getTodayClass/' + user_id,
		}),

	getUpcommingClass: (user_id) =>
		instance({
			method: 'GET',
			url: 'getUpcommingClass/' + user_id,
		}),

	getHistoryClass: (user_id) =>
		instance({
			method: 'GET',
			url: 'getHistoryClass/' + user_id,
		}),

	getScheduledClass: (user_id) =>
		instance({
			method: 'GET',
			url: 'getScheduledClass/' + user_id,
		}),

	getClassTime: () =>
		instance({
			method: 'GET',
			url: 'getClassTime',
		}),

	findClasses: (teacherid) =>
		instance({
			method: 'GET',
			url: 'findClasses/' + teacherid,
		}),

	review_rating: (params, userId, rating) => {
		return axios.post(nodeUrl + 'review_rating/' + userId + '/' + rating, {
			formdata: params,
		});
	},

	getPlanList: (userId) =>
		instance({
			method: 'GET',
			url: 'getPlansBycountry/' + userId,
		}),

	getPaymentHistory: (user_id) =>
		instance({
			method: 'GET',
			url: 'getPaymentHistory/' + user_id,
		}),

	StudentChangePassword: (params, userId) => {
		return axios.post(nodeUrl + 'StudentChangePassword/' + userId, {
			formdata: params,
		});
	},

	getTeacherById: (teacherId) =>
		instance({
			method: 'GET',
			url: 'getTeacherById/' + teacherId,
		}),

	getBookedClassData: (user_id) =>
		instance({
			method: 'GET',
			url: 'bookedClasses/' + user_id,
		}),

	getStudentProfile: (user_id) =>
		instance({
			method: 'GET',
			url: 'getStudentProfile/' + user_id,
		}),

	forgotpasswordWithEmailANDMobile: (params) => {
		return axios.post(nodeUrl + 'forgotpasswordWithEmailANDMobile', {
			formdata: params,
		});
	},

	forgotpasswordWithMobile: (params) => {
		return axios.post(nodeUrl + 'forgotpasswordWithMobile', {
			formdata: params,
		});
	},

	loginUserWithMailMobile: (params) => {
		return axios.post(nodeUrl + 'loginUserWithMailMobile', {
			formdata: params,
		});
	},

	registerTeacherWithMail: (formdata, selectedFile) => {
		const formData = new FormData();
		formData.append('selectedFile', selectedFile);
		formData.append('params', formdata);
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};
		return axios.post(nodeUrl + 'registerTeacherMobile', formData, config);
	},

	stripePayment: (params) => {
		return axios.post(nodeUrl + 'stripePayment', {
			formdata: params,
		});
	},

	paymentStripeSuccess: (params) => {
		return axios.post(nodeUrl + 'paymentStripeSuccess', {
			formdata: params,
		});
	},

	getNextPaymentDate: (user_id) =>
		instance({
			method: 'GET',
			url: 'getNextPaymentDate/' + user_id,
		}),

	studentloginbyadmin: (user_id) =>
		instance({
			method: 'GET',
			url: 'studentloginbyadmin/' + user_id,
		}),

	getProfileData: (user_id) =>
		instance({
			method: 'GET',
			url: 'getProfileData/' + user_id,
		}),

	updateProfileImage: (formData) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};
		return axios.post(nodeUrl + 'userImageUpdate', formData, config);
	},

	UpdateProfile: (params) => {
		return axios.post(nodeUrl + 'UpdateProfile', {
			formdata: params,
		});
	},

	downloadDemoCertificate: (params) => {
		return axios.post(nodeUrl + 'downloadDemoCertificate/1', {
			formdata: params,
		});
	},
	getDemoCertificate: (params) => {
		return axios.get(nodeUrl + 'uploads/' + params);
	},

	getWorkshopList: async () => await axios.get(`${nodeUrl}getWorkshopList`),

	getUsersPaymentList: (user_id) =>
		instance({
			method: 'GET',
			url: 'getUsersPaymentList/' + user_id,
		}),
};
