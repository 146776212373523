import React from 'react';

// Integrate the SDK
import {ZIMKitManager, Common} from '@zegocloud/zimkit-react';
import '@zegocloud/zimkit-react/index.css';

// The following uses the App instance as an example.
const userId = localStorage.getItem('user_id');

export default class Chats extends React.Component {
	constructor() {
		super();
		this.state = {
			appConfig: {
				appID: 8754834, // The AppID you get from the ZEGOCLOUD admin console.
				serverSecret: '440a9cebb92981b7ebe135fe604bd45d', // The serverSecret you get from ZEGOCLOUD Admin Console.
			},
			// The userID and userName is a strings of 1 to 32 characters.
			// Only digits, letters, and the following special characters are supported: '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '=', '-', '`', ';', '’', ',', '.', '<', '>', '/', '\'
			userInfo: {
				// Your ID as a user.
				userID: userId + userId + userId,
				// Your name as a user.
				userName: 'user' + userId,
				// The image you set as a user avatar must be network images. e.g., https://storage.zego.im/IMKit/avatar/avatar-0.png
			},
		};
	}

	async componentDidMount() {
		const zimKit = new ZIMKitManager();
		const token = zimKit.generateKitTokenForTest(
			this.state.appConfig.appID,
			this.state.appConfig.serverSecret,
			this.state.userInfo.userID
		);
		await zimKit.init(this.state.appConfig.appID);
		await zimKit.connectUser(this.state.userInfo, token);
	}
	render() {
		console.log('====================================');
		console.log(this.state.userInfo.userID);
		console.log('====================================');
		return <Common></Common>;
	}
}
