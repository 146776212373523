/* eslint-disable react/jsx-pascal-case */
import React, {useState} from 'react';
import {Sidebar_inner} from '../../components';
import {ZIMKitManager, Common} from '@zegocloud/zimkit-react';
import '@zegocloud/zimkit-react/index.css';
import Teacher_sidebar from '../../components/Teacher_sidebar';
const userId = localStorage.getItem('user_id');

const authData = JSON.parse(localStorage.getItem('authData'));
const teacher = JSON.parse(localStorage.getItem('teacher'));
export default class Message extends React.Component {
	constructor() {
		super();
		this.state = {
			appConfig: {
				appID: 8754834, // The AppID you get from the ZEGOCLOUD admin console.
				serverSecret: '440a9cebb92981b7ebe135fe604bd45d', // The serverSecret you get from ZEGOCLOUD Admin Console.
			},
			// The userID and userName is a strings of 1 to 32 characters.
			// Only digits, letters, and the following special characters are supported: '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '=', '-', '`', ';', '’', ',', '.', '<', '>', '/', '\'
			userInfo: {
				// Your ID as a user.
				userID: authData.admission_no,
				// Your name as a user.
				userName: authData.name + authData.last_name,
				// The image you set as a user avatar must be network images. e.g., https://storage.zego.im/IMKit/avatar/avatar-0.png
				userAvatarUrl: authData.img,
			},
			auth: {
				name: authData.name,
				email: authData.email,
				phone: authData.phone,
				admission_no: authData.admission_no,
			},
		};
	}

	async componentDidMount() {
		const zimKit = new ZIMKitManager();
		const token = zimKit.generateKitTokenForTest(
			this.state.appConfig.appID,
			this.state.appConfig.serverSecret,
			this.state.userInfo.userID
		);
		await zimKit.init(this.state.appConfig.appID);
		await zimKit.connectUser(this.state.userInfo, token);
	}
	render() {
		return (
			<div className="take_admin_main_wrapper">
				{!teacher ? <Sidebar_inner /> : <Teacher_sidebar />}

				<div className="take_top_header">
					<h2 className="take_title h2">
						{teacher ? 'teacher' : 'user'} ID:{' '}
						{teacher ? teacher.id : this.state.auth.admission_no}{' '}
					</h2>
					<p />
				</div>

				<div className="take_body">
					<Common></Common>
				</div>
			</div>
		);
	}
}
