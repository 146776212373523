import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import piano2 from '../../../assets/images/blogs/piano2.png';
import piano from '../../../assets/images/blogs/piano.png';
import piano1 from '../../../assets/images/blogs/piano1.png';

const Piano = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={piano2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									The piano has a massive presence in the world of music. The
									instrument is an essential element in many types of music,
									including classical, jazz and rock. Keyboard instruments have
									been around for a while, but the invention of the piano by
									Bartolomeo Cristofori in 1709 represents an important
									milestone in their history. The name comes from the Italian
									word "pianoforte," which means "soft-loud" because it can
									produce both soft and loud sounds. Pianos can be classified
									into two main types: grand and upright. The grand piano- an
									instrument, produces a beautiful sound that can fill a large
									room with music. It is a large, heavy instrument that has a
									larger soundboard and strings than an upright piano. They are
									usually kept vertically on the floor or on top of a table. An
									upright piano is also called a baby grand or vertical piano.
									They are smaller than a traditional grand piano but have the
									same number of keys and strings.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={piano}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">Ways to practice the Piano:</h2>
										<p>
											history. The name comes from the Italian word
											"pianoforte," which means "soft-loud" because it can
											produce both soft and loud sounds. Pianos can be
											classified into two main types: grand and upright. The
											grand piano- an instrument, produces a beautiful sound
											that can fill a large room with music. It is a large,
											heavy instrument that has a larger soundboard and strings
											than an upright piano. They are usually kept vertically on
											the floor or on top of a table. An upright piano is also
											called a baby grand or vertical piano. They are smaller
											than a traditional grand piano but have the same number of
											keys and strings.
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning how to play the piano can be challenging. It
											takes time and patience. There are plenty of free
											resources available on internet such as practice videos or
											even written tutorials. Still, the best effective way is
											to follow the structured lessons from an expert trainer.
											Take5 Music offers structured learning opportunities
											through experienced and qualified trainers who provide
											live online classes. All you need is a computer or laptop
											with internet access and a few hours of spare time each
											day.
										</p>
									</div>
									<img
										src={piano1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Piano;
