import React, {useCallback, useState} from 'react';
import Sidebar_inner from '../../../components/Sidebar_Inner';
import Header_Inner from '../../../components/common/Header_Inner';
import {ZegoUIKitPrebuilt} from '@zegocloud/zego-uikit-prebuilt';

const VideoCall = () => {
	const userId = localStorage.getItem('user_id');
	const authData = localStorage.getItem('authData');
	console.log('====================================');
	console.log('userId', JSON.parse(authData)['4']);
	console.log('====================================');
	const roomID = 'trialrun';
	let myMeeting = async (element: HTMLDivElement) => {
		// generate Kit Token
		if (userId !== null) {
			const appID = 2052101334;
			const serverSecret = 'eb701946f832ff2a005c3eb8438ef61f';
			const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
				appID,
				serverSecret,
				roomID,
				userId,
				JSON.parse(authData)['5'].toString() +
					' ' +
					JSON.parse(authData)['4'].toString()
			);

			// Create instance object from Kit Token.
			const zp = ZegoUIKitPrebuilt.create(kitToken);
			// start the call
			zp.joinRoom({
				container: element,
				sharedLinks: [
					{
						name: 'Copy link',
						url:
							window.location.protocol +
							'//' +
							window.location.host +
							window.location.pathname +
							'?roomID=' +
							roomID,
					},
				],
				scenario: {
					mode: ZegoUIKitPrebuilt.GroupCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
				},
			});
		} else {
			window.location.href = '/login';
		}
	};
	return (
		<div className="take_admin_main_wrapper">
			<Sidebar_inner />
			<div class="take_top_header">
				<Header_Inner />
				<h2 class="take_title h2">Online Class</h2>
				<div ref={myMeeting} style={{width: '100%', height: '100%'}}></div>
			</div>
		</div>
	);
};

export default VideoCall;
