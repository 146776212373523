import html2canvas from 'html2canvas';
import React, {useContext, useState, useEffect} from 'react';
import {useAlert} from 'react-alert';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Api from '../services/Api';
import {authContext} from '../auth/AuthContext';
import Helper from '../auth/Helper';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const authData = JSON.parse(localStorage.getItem('authData'));
const teacher = localStorage.getItem('teacher');
const Teacher_sidebar = (props) => {
	const alert = useAlert();
	const [renderPdf, setrenderPdf] = useState('');
	const options = {
		cMapUrl: 'cmaps/',
		cMapPacked: true,
	};
	const [chat, setChat] = useState(false);
	const [teacher, setTeacher] = useState(true);
	useEffect(() => {
		if (authData.status === 'approved') {
			setChat(true);
		}
		if (teacher !== null) {
			setTeacher(true);
		}
	}, [chat, teacher]);
	const {auth} = useContext(authContext);

	let userId = 0;
	let userImg = 0;
	let userName = '';
	if (auth.data) {
		userId = auth.data.id;
		userImg = auth.data.img;
		userName = auth.data.name;
	}

	const [toggleMenuData, settoggleMenuData] = useState(0);
	const activeMenuData = window.location.pathname;
	const [file, setFile] = useState('');
	const [numPages, setNumPages] = useState(null);

	function logout() {
		localStorage.removeItem('plan_className_id');

		alert.show('Log out Successfully', {
			timeout: 2000, // custom timeout just for this one alert
			type: 'success', // callback that will be executed after this alert open
			/*onOpen: () => {
			  
			}, */ onClose: () => {
				localStorage.clear();
				localStorage.removeItem('plan_className_id');
				window.location.href = '/';
			},
		});
	}

	const logo = {
		width: '155px',
	};

	function onDocumentLoadSuccess({numPages: nextNumPages}) {
		setNumPages(nextNumPages);
	}
	function onDocument2Canvas() {
		const input = document.getElementById('divToPrint');
		const canvas = html2canvas(input).then((canvas) => {
			var link = document.createElement('a');
			link.setAttribute('download', 'Certificate.png');
			link.setAttribute(
				'href',
				canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
			);
			link.click();
		});
	}
	const downloadDemoCertificate = () => {
		Helper.getAtuhData().then((result) => {
			var obj = {
				name: result.name,
				last_name: result.last_name,
			};
			console.log(obj);
			Api.downloadDemoCertificate(obj).then((response) => {
				console.log(response.data.file);
				// let file = "http://localhost:3000/uploads/"+response.data.file;
				const file_new = response.data.file;
				//console.log(file_new);

				confirmAlert({
					customUI: ({onClose}) => {
						return (
							<div class="take_modal">
								<div class="take_modal_inner">
									<div class="take_modal_header">
										<h5 class="take_modaltitle" id="pdfViewer">
											Certificate Preview
										</h5>
										<button
											onClick={() => {
												onClose();
											}}
											type="button"
											class="close"
											data-dismiss="modal"
											aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div class="take_modal_body">
										<div class="take_step_wrapper">
											<div
												className="Example__container__document"
												id="divToPrint">
												<Document
													URL="https://take5music.in/take5app1/studentApi/uploads/haresh_testing.pdf"
													onLoadSuccess={onDocumentLoadSuccess}
													options={options}
													id="divToPrint">
													<Page pageNumber={1} />
												</Document>
											</div>

											<div class="take_step_btns">
												<a
													onClick={(e) => {
														onDocument2Canvas(file_new);
													}}
													class="take_btn take_next">
													Print
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					},
				});
			});
		});
	};

	return (
		<div class="take_sidebar_wrapper">
			<div class="take_logo">
				<a href="/">
					<picture>
						<source
							media="(max-width:991px)"
							srcset="assets/images/logo_icon.png"
						/>
						<img src="assets/images/logonew.png" class="img-fluid" alt="" />
					</picture>
				</a>
			</div>

			<div class="take_menu">
				<ul>
					<li>
						<a
							className={activeMenuData == '/teacher-dashboard' && 'active'}
							href="teacher-dashboard">
							<svg
								width="28"
								height="25"
								viewBox="0 0 28 25"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M14.6745 0.365677C14.2924 0.0169974 13.7076 0.0171651 13.3258 0.366064L0.323516 12.2473C-0.077346 12.6136 -0.101015 13.237 0.27092 13.6327C0.635604 14.0206 1.24429 14.0436 1.63717 13.6842L2.22425 13.1473V22.007C2.22425 23.2458 3.22847 24.25 4.46724 24.25H23.5326C24.7714 24.25 25.7756 23.2458 25.7756 22.007V13.1473L26.3626 13.6841C26.7555 14.0435 27.3643 14.0205 27.729 13.6326C28.101 13.2369 28.0774 12.6135 27.6766 12.2472L21.8504 6.92038V2.04463C21.8504 1.50267 21.411 1.06332 20.8691 1.06332C20.3271 1.06332 19.8878 1.50267 19.8878 2.04463V5.12401L14.6745 0.365677ZM13.3248 2.99467C13.7069 2.64489 14.293 2.64488 14.6751 2.99467L23.813 11.3585V22.3179H17.9252V13.6567C17.9252 13.1044 17.4775 12.6567 16.9252 12.6567H11.0747C10.5224 12.6567 10.0747 13.1044 10.0747 13.6567V22.3179H4.18688V11.3585L13.3248 2.99467ZM12.0373 14.589H15.9626V22.3178H12.0373V14.589Z"
								/>
							</svg>
							<span>Home</span>
						</a>
					</li>

					<li>
						<a className={activeMenuData == '/chats' && 'active'} href="chats">
							<svg
								width="28"
								height="28"
								viewBox="0 0 28 28"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9.99738 12.6562C9.99738 12.225 10.3474 11.875 10.7786 11.875H19.2186C19.4258 11.875 19.6245 11.9573 19.7711 12.1038C19.9176 12.2503 19.9999 12.449 19.9999 12.6562C19.9999 12.8634 19.9176 13.0622 19.7711 13.2087C19.6245 13.3552 19.4258 13.4375 19.2186 13.4375H10.7786C10.3474 13.4375 9.99738 13.0875 9.99738 12.6562ZM10.7811 16.5475C10.5739 16.5475 10.3752 16.6298 10.2287 16.7763C10.0822 16.9228 9.99988 17.1215 9.99988 17.3287C9.99988 17.5359 10.0822 17.7347 10.2287 17.8812C10.3752 18.0277 10.5739 18.11 10.7811 18.11H16.7186C16.9258 18.11 17.1245 18.0277 17.2711 17.8812C17.4176 17.7347 17.4999 17.5359 17.4999 17.3287C17.4999 17.1215 17.4176 16.9228 17.2711 16.7763C17.1245 16.6298 16.9258 16.5475 16.7186 16.5475H10.7811ZM10.3349 3.4075C21.1399 -0.917502 31.8011 10.5544 25.9955 21.005C23.358 25.7512 16.8068 29.5737 9.178 25.9587L3.87488 27.4737C3.05238 27.7087 2.29175 26.9512 2.523 26.1281C2.83925 24.9994 3.58925 22.3381 4.028 20.8956C0.739252 15.0281 2.95675 6.36 10.3343 3.4075H10.3349ZM24.6299 20.2462C29.683 11.1494 20.428 1.05125 10.9155 4.85812C4.36738 7.47812 2.46175 15.2912 5.54363 20.395L5.7255 20.6962L5.6205 21.0319C5.288 22.0962 4.65988 24.3031 4.25488 25.74L9.32613 24.2912L9.59675 24.4256C16.4761 27.8356 22.298 24.4425 24.6299 20.2456V20.2462Z"
									fill-rule="evenodd"
									clip-rule="evenodd"
								/>
							</svg>

							<span>Chats</span>
						</a>
					</li>
				</ul>
			</div>

			<p
				onClick={(e) => {
					logout();
				}}
				class="take_logout">
				<a>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M3.25 23.25C1.875 23.25 0.75 22.125 0.75 20.75L0.75 10.75H3.25L3.25 20.75H20.75V10.75H23.25V20.75C23.25 22.125 22.125 23.25 20.75 23.25H3.25ZM10.75 5.53L7.58 8.7L5.8125 6.9325L12 0.745003L18.1875 6.9325L16.42 8.7L13.25 5.53V13.7625H10.75V5.53Z"
							fill="#17204F"
						/>
					</svg>{' '}
					<span>logout</span>{' '}
				</a>
			</p>
		</div>
	);
};
export default Teacher_sidebar;
