import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {
	About,
	Blogs,
	Blogdetails1,
	Blogdetails2,
	Blogdetails3,
	BookedClasses,
	ChangePassword,
	Contact,
	Course_schedule,
	ComingSoon,
	Explore,
	Faq,
	FindClasses,
	ForgotPassword,
	ForgotPasswordMobile,
	Home,
	Instruments,
	InstrumentBlog,
	Login,
	LoginWithEmail,
	Message,
	Payments,
	PaymentStripe,
	PaymentStripeSuccess,
	Plans,
	Privacy,
	Pricing,
	Profile,
	Register,
	RegisterPassword,
	RegisterTeacher,
	StudentDashboard,
	TeacherDashboard,
	StudentProfile,
	Summercamp_Thanks,
	Summercamp_Verify_OTP,
	Team,
	Term,
	TeacherProfile,
	Teachers,
	TrasactionHistory,
	VerifyOTP,
	VerifyPasswordConfirm,
	verifyRegisterOTP,
	VerifyOTPForgotPassword,
	Guitar,
	Piano,
	Violin,
	Flute,
	Drums,
	Tabla,
	Sarangi,
	Hindustani,
	Carnatic,
	Western,
	VideoCall,
} from '../pages';
import {LoginByadmin} from '../components';
import {NotFound} from '../components';
import TeacherVideoCall from '../pages/teacher/TeacherVideoCall';

const hashHistory = Router.hashHistory;
const teacher = localStorage.getItem('teacher');
console.log('====================================');
// console.log(teacher.length, 'teacher from routes');
console.log('====================================');
const Routes = (props) => (
	<Router {...props} history={hashHistory}>
		<Switch>
			<Route exact path="/" component={Home} />
			<Route path="/contact" component={Contact} />
			<Route path="/teachers-:instrumentid" component={Teachers} />
			<Route path="/teacher-profile-:teacherId" component={TeacherProfile} />

			<Route path="/instruments" component={Instruments} />
			<Route exact path="/instruments-1" component={Guitar} />
			<Route exact path="/instruments-2" component={Carnatic} />
			<Route exact path="/instruments-3" component={Piano} />
			<Route exact path="/instruments-4" component={Violin} />
			<Route exact path="/instruments-5" component={Flute} />
			<Route exact path="/instruments-6" component={Drums} />
			<Route exact path="/instruments-7" component={Tabla} />
			<Route exact path="/instruments-8" component={Hindustani} />
			<Route exact path="/instruments-9" component={Western} />
			<Route exact path="/instruments-10" component={Sarangi} />
			<Route path="/register" component={Register} />
			{/* <Route path="/registerwithpin" component={RegisterWithPin} /> */}
			<Route path="/login" component={Login} />
			<Route path="/about" component={About} />
			<Route path="/LoginWithEmail" component={LoginWithEmail} />

			{teacher ? (
				<Route path="/student-dashboard" component={TeacherDashboard} />
			) : (
				<Route path="/student-dashboard" component={StudentDashboard} />
			)}
			<Route path="/teacher-dashboard" component={TeacherDashboard} />
			<Route path="/student-profile" component={StudentProfile} />
			<Route path="/change-password" component={ChangePassword} />
			<Route path="/find-classes-:teacherid" component={FindClasses} />
			<Route path="/plans-:class_id" component={Plans} />

			<Route path="/book-class-:class_id" component={Plans} />

			<Route path="/terms-condition" component={Term} />
			<Route path="/privacy-policy" component={Privacy} />

			<Route path="/payment-history" component={TrasactionHistory} />

			<Route path="/booked-classes" component={BookedClasses} />

			<Route path="/forgot_password" component={ForgotPassword} />
			<Route path="/forgot_password_mobile" component={ForgotPasswordMobile} />
			<Route path="/verify-OTP" component={VerifyOTP} />
			<Route path="/explore" component={Explore} />

			<Route path="/verify-OTP-forgot" component={VerifyOTPForgotPassword} />
			<Route
				path="/verify-password-confirm"
				component={VerifyPasswordConfirm}
			/>
			<Route path="/team" component={Team} />
			<Route path="/faq" component={Faq} />
			<Route path="/blog" component={Blogs} />
			<Route path="/register-password" component={RegisterPassword} />
			<Route path="/profile" component={Profile} />
			<Route path="/course-schedule" component={Course_schedule} />
			<Route path="/pricing" component={Pricing} />
			<Route path="/loginByadmin-:stid" component={LoginByadmin} />

			<Route path="/PaymentStripe" component={PaymentStripe} />
			<Route path="/paymentSuccess" component={PaymentStripeSuccess} />
			<Route path="/chats" component={Message} />
			<Route path="/online-class" component={VideoCall} />
			<Route path="/class/:roomID" component={TeacherVideoCall} />
			<Route path="/payments-history" component={Payments} />
			{/* <Route path="/workshop" component={Workshop} /> */}
			<Route path="/workshop_thanks" component={Summercamp_Thanks} />
			<Route path="/workshop_verify_OTP" component={Summercamp_Verify_OTP} />
			<Route path="/blog001" component={Blogdetails1} />
			<Route path="/blog002" component={Blogdetails2} />
			<Route path="/blog003" component={Blogdetails3} />
			<Route path="/registerTeacher" component={RegisterTeacher} />
			<Route path="*" component={NotFound} />
		</Switch>
	</Router>
);
export default Routes;
