import axios from 'axios';
import React from 'react';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../components';
import {Header} from '../../components';

class Home extends React.Component {
	constructor() {
		super();
		this.state = {
			categoryData: [],
			cartData: [],
			productData: [],
		};
	}

	componentDidMount() {
		//localStorage.removeItem("cartLocalData");
		/*Api.getCategortyItems().then(
			(response)=>{
				this.setState({categoryData:response.data.data});
			}
		);

		Api.getAllProduct().then(
			(response)=>{
				this.setState({productData:response.data.data});
			}
		);*/

		const vw = Math.max(
			document.documentElement.clientWidth || 0,
			window.innerWidth || 0
		);

		const getData = async () => {
			const res = await axios.get('https://geolocation-db.com/json/');
			console.log('haresh' + res.data.country_code);
			if (res.data.country_code === 'IN') {
				// setIsIndia(0);
				localStorage.setItem('isIndia', 0);
			} else {
				localStorage.setItem('isIndia', 1);
				// setIsIndia(1);
			}

			//setCountryCode(res.data.country_code)
		};

		getData();

		// if (vw < 768) {
		// 	summercampModel_mobile();
		// } else {
		// 	summercampModel_full();
		// }

		// summercampModel();

		/*let  cartdata =   JSON.parse(localStorage.getItem('cartLocalData'));
		this.setState({	cartData :cartdata });
		cart_data={this.state.cartData}*/

		function summercampModel() {
			confirmAlert({
				customUI: ({onClose}) => {
					return (
						<div class="take_offer_wrapper">
							<div className="take_offer_inner">
								<div class="d-flex justify-content-between">
									<div class="take_left">
										<div class="take_logo">
											<a href="#">
												<img src="assets/images/logo_pop.png" alt="" />
											</a>
										</div>
										<img src="assets/images/left_img.png" alt="" />
									</div>
									<div class="take_right">
										<h2>
											<span>Free</span> 5-day
											<br />
											Music Summer
											<br />
											Camp
										</h2>
										<h4>Monday - Friday, April 18th to 22nd</h4>

										<div class="take_day_wrap">
											<span class="take_day_box">
												<span>
													<b>Day 1</b>
												</span>
												<span>Basics of Music Education</span>
											</span>
											<span class="take_day_box">
												<span>
													<b>Day 2</b>
												</span>
												<span>Types of Instruments</span>
											</span>
											<span class="take_day_box">
												<span>
													<b>Day 3</b>
												</span>
												<span>Pitch & Rhythm </span>
											</span>
											<span class="take_day_box">
												<span>
													<b>Day 5</b>
												</span>
												<span>Summary & Certificate Issue</span>
											</span>
											<span class="take_day_box">
												<span>
													<b>Day 4</b>
												</span>
												<span>Hit Songs</span>
											</span>
										</div>

										<button class="take_btn_regi">Register now</button>
									</div>
								</div>
								<p>No Instruments Required</p>
							</div>
						</div>
					);
				},
			});
		}

		function summercampModel_full() {
			confirmAlert({
				customUI: ({onClose}) => {
					return (
						<div class="take_offer_wrapper">
							<div className="take_offer_inner">
								<div class="d-flex justify-content-between">
									<div class="take_left offer-left">
										<div class="take_logo">
											<a href="#">
												<img src="assets/images/logo_pop.png" alt="" />
											</a>
											<button class="desktop_close_button" onClick={onClose}>
												X
											</button>
										</div>
										<img src="assets/images/left_img.png" alt="" />
									</div>
									<div class="take_right">
										<div class="take_day_wrap_header">
											<h2>Live Music Workshops</h2>
											<h4>Every Saturday @ 07:00 PM IST</h4>
											<h6>No Instruments Required</h6>
										</div>
										<div class="take_day_wrap">
											<span class="take_day_box">
												{/* <span><b>Day 1</b></span> */}
												<span>Basics of Music Education</span>
											</span>
											<span class="take_day_box">
												{/* <span><b>Day 2</b></span> */}
												<span>Types of Instruments</span>
											</span>
											<span class="take_day_box">
												{/* <span><b>Day 3</b></span> */}
												<span>Pitch & Rhythm </span>
											</span>
											<br />
											{/* <span class="take_day_box">
                                    <span><b>Day 5</b></span>
                                    <span>Summary 
                                    & Certificate Issue</span>
                                </span>
                                <span class="take_day_box">
                                    <span><b>Day 4</b></span>
                                    <span>Hit Songs</span>
                                </span> */}
										</div>

										<button class="take_btn_regi">
											<a href="/workshop">Register now</a>
										</button>
									</div>
								</div>
							</div>
						</div>
					);
				},
			});
		}

		function summercampModel_mobile() {
			confirmAlert({
				customUI: ({onClose}) => {
					return (
						<>
							<div id="react-confirm-alert">
								<div class="react-confirm-alert-overlay undefined">
									<div class="react-confirm-alert">
										<div class="take_offer_mobile">
											<div class="take_offer_summer">
												<div class="align-items-center">
													<div class="take_left_mobile">
														<div class="take_logo take_logo_mobile">
															<a href="#">
																<img src="assets/images/logo_pop.png" alt="" />
															</a>
															<button
																class="mobile_close_button"
																onClick={onClose}>
																X
															</button>
														</div>
														<div class="take_mobile_right">
															<div class="take_mobile_wrap_header">
																<h2>Live Music Workshops</h2>
																<h4 class="m-auto text-sm">
																	Every Saturday @ 07:00 PM IST
																</h4>
															</div>
															<p class="font-weight-bold text-dark lower-mobile-text">
																No Instruments Required
															</p>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="left_img_mobile">
														<img
															src="assets/images/left_img_mobile.png"
															alt=""
														/>
													</div>
													<div class="take_mobile_wrap ">
														<div class="button_mobile_1">
															<span class="take_day_box_mobile">
																{/* <span>
                            <b>Day 1</b>
                        </span> */}
																<span>Basics of Music Education</span>
															</span>
														</div>
														<div class="button_mobile_2">
															<span class="take_day_box_mobile">
																{/* <span><b>Day 2</b></span> */}
																<span>Types of Instruments</span>
															</span>
														</div>{' '}
														<div class="button_mobile_3">
															<span class="take_day_box_mobile">
																{/* <span><b>Day 3</b></span> */}
																<span>Pitch &amp; Rhythm </span>
															</span>{' '}
														</div>
														{/* <div class="button_mobile_5"><span class="take_day_box_mobile"><span><b>Day 4</b></span><span>Hit Songs</span></span> </div> 
                         <div class="button_mobile_4"><span class="take_day_box_mobile"><span><b>Day 5</b></span><span>Summary &amp; Certificate Issue</span></span> </div>  */}
													</div>
												</div>
											</div>
											<button class="take_btn_regi_mobile">
												<a href="/workshop">Register now</a>
											</button>
										</div>
									</div>
								</div>
							</div>
							<div id="__react-alert__" />
						</>
					);
				},
			});
		}
	}

	render() {
		return (
			<div className="take_main_wrapper">
				<Header />

				<div className="take_banner_wrapper">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12">
								<div className="take_banner_video">
									<iframe
										className="take_banner_video"
										src="https://www.youtube.com/embed/dGruF8_2TUw"
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
									/>
									<span className="d-none">
										<img src="assets/images/svg/play.svg" alt="" />
									</span>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12">
								<div className="take_banner_detail">
									<p className="take_subheading text-uppercase">
										THE FINEST ONLINE MUSIC LEARNING PLATFORM
									</p>
									<h1>
										Learning Music{' '}
										<span className="take_orange">Made Easy</span> and Fun Only
										at Take5 Music!
									</h1>
									<p>
										Take5 Music offers various interactive live music classes.
										Your first class with us will tell you everything! Book a
										free demo today!
									</p>

									<div className="take_btn_wrapper">
										<a
											target="_new"
											href="/register"
											className="take_btn with_arrow take_enroll_btn">
											Book a free trial
											<span>
												<img
													className="arrow1"
													src="assets/images/svg/btn_arrow1.svg"
													alt=""
												/>
												<img
													className="arrow2"
													src="assets/images/svg/btn_arrow2.svg"
													alt=""
												/>
											</span>
										</a>

										<a
											href="/explore"
											className="take_btn with_arrow take_enroll_btn">
											Explore About us
											<span>
												<img
													className="arrow1"
													src="assets/images/svg/btn_arrow1.svg"
													alt=""
												/>
												<img
													className="arrow2"
													src="assets/images/svg/btn_arrow2.svg"
													alt=""
												/>
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<span className="take_banner_wave">
						<img src="assets/images/wave_white.png" alt="" />
					</span>
				</div>

				<Footer />
			</div>
		);
	}
}

export default Home;
