import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import violin2 from '../../../assets/images/blogs/violin2.png';
import violin from '../../../assets/images/blogs/violin.png';
import violin1 from '../../../assets/images/blogs/violin1.png';

const Violin = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={violin2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									Violin is one of the most popular musical instruments in the
									world and has been around for more than 400 years. It was
									invented in the 16th century and became popular in the 18th
									century. Violinists are often called "fiddlers." Violins are a
									part of the larger family of instruments known as violins,
									cellos and violas. Violins are used in orchestras and string
									quartets. They are also used to play solo pieces. The modern
									violin that we know today was created by luthier Antonio
									Stradivari in 1698. He used maple for his violins’ bodies
									instead of willow or pine and he used steel strings instead of
									gut ones to make them more powerful. There are four types of
									violins: violin, viola, cello and double bass. The violin is
									the smallest violin and it is also the most common type of
									violin used by professional musicians today. Violas are a
									little bit bigger than violins but not as big as cellos or
									double basses. Cellos are even bigger than violas and double
									basses are the biggest type of violin there is.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={violin}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">Ways to practice the Violin:</h2>
										<p>
											Practising the violin is a skill that needs to be learned.
											There are many ways to go about this, and it is important
											to find what works best for you.
											<br />
											1) Practice for at least 20 minutes a day, 3-4 times a
											week. <br />
											2) Practice with music or an accompaniment track. <br />
											3) Record yourself and listen back to your playing later
											on. <br />
											4) Play in front of other people or in front of a mirror
											so that you can see your technique and make adjustments as
											needed. <br />
											5) Practice with a metronome to help you maintain a steady
											tempo. <br />
											6) Play scales and arpeggios in all positions on the
											fingerboard. <br />
											7) Play with different dynamics and tempos, making sure to
											use a variety of bowings and articulations.
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning to play the violin is not always easy. It takes
											time and patience. There are plenty of free resources
											available on internet such as practice videos or even
											written tutorials, but the best effective way is to follow
											a structured lesson from an expert trainer. Take5 Music
											offers structured learning opportunities through
											experienced and qualified trainers who provide live online
											classes. All you need is a computer or laptop with
											internet access and a few hours of spare time each day.
										</p>
									</div>
									<img
										src={violin1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Violin;
