import React from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Footer} from '../../../components';
import {Header} from '../../../components';
import western2 from '../../../assets/images/blogs/western2.png';
import western from '../../../assets/images/blogs/western.png';
import western1 from '../../../assets/images/blogs/western1.png';

const Western = () => {
	return (
		<>
			<Header />

			<div class=" take_instrument_wrapper">
				<img
					src={western2}
					style={{width: '100%', height: 'auto', margin: 'auto'}}
					alt=""
				/>
				<div class="container" style={{marginTop: '25px'}}>
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper ">
								<h2 className="h2">Overview</h2>
								<p>
									The western vocal tradition is a tradition that has been
									passed down from generation to generation. It has a long and
									rich history, with many different styles and genres of music.
									Western vocal music can be classified into two main
									categories: art songs and popular songs. Art songs are more
									complex, with more difficult melodies, while popular songs are
									simpler and easier to sing. Western vocals are a type of
									singing which originated in western Europe, North America and
									Latin America. It is a genre of popular music that is
									typically sung without instrumental accompaniment. Western
									vocals are typically performed live with the singer being
									backed by an electric guitar, acoustic guitar, drums and bass
									guitar. Types of Western vocals: - Classical singing:
									Classical singing is a vocal style that originated in Italy
									and uses a wide range of vocal techniques. It is primarily
									associated with the opera but can be used in other types of
									music. - Folk Singing: Folk singing has been a tradition of
									people in rural areas. Singing either solo or with others
									accompanies other activities such as storytelling and social
									events - Gospel Singing: Gospel music is a genre of Christian
									music that focuses on telling the story of Jesus. These songs
									are often about redemption and salvation from sin, as well as
									faith in Jesus Christ. - Jazz Singing: One major element of
									jazz singing is improvisation. People from all different
									backgrounds use improvisation to create the melody for their
									songs and make it their own.
								</p>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<img
										src={western}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',

											flexDirection: 'column',
										}}>
										<h2 className="h2">Ways to practice Western vocals</h2>
										<p>
											There are many ways to practice Western vocal skills. One
											of the most popular and effective ways is by using the
											diaphragm. You can also practice your throat muscles by
											humming or singing with a closed mouth. Western vocal
											practice is essential to the singer's development. It is a
											process that includes: <br />
											1. Breathing exercises, vocal warm-up exercises, voice
											strengthening exercises <br />
											2. Sing along with a song, practice scales, practice
											singing lyrics <br />
											3. Record yourself and critique yourself <br />
											4. Ear training and listening skills, chord identification
											and analysis
										</p>
									</div>
								</div>
							</div>
							<div class="take_heading_wrapper ">
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										flexDirection: 'row',
										width: '100%',
									}}>
									<div
										style={{
											width: '50%',
											// height: 'auto',
											display: 'flex',
											flexDirection: 'column',
										}}>
										<h2 className="h2">What Take5 offers:</h2>
										<p>
											Learning Western vocals is not an easy task. It requires
											patience and dedication to master the art form. There are
											plenty of free resources available on internet such as
											practice videos or even written tutorials, but the best
											effective way is to follow a structured lesson from an
											expert trainer. Take5 Music offers structured learning
											opportunities through experienced and qualified trainers
											who provide live online classes. All you need is a
											computer or laptop with internet access and a few hours of
											spare time each day.
										</p>
									</div>
									<img
										src={western1}
										style={{
											width: '40%',
											height: 'auto',
										}}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Western;
