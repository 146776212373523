import React, {useEffect, useState} from 'react';

import {ZegoUIKitPrebuilt} from '@zegocloud/zego-uikit-prebuilt';
import {useParams} from 'react-router-dom';
import logonew from '../../assets/images/logonew.png';
import {ZegoSuperBoardManager} from 'zego-superboard-web';

const TeacherVideoCall = () => {
	// const teacher = JSON.parse(localStorage.getItem('teacher'));
	const userId = localStorage.getItem('user_id');
	const authData = localStorage.getItem('authData');
	const [isTeacher, setIsTeacher] = useState(false);
	console.log('====================================');
	console.log(authData);
	console.log('====================================');
	const [teacher, setTeacher] = useState(
		JSON.parse(localStorage.getItem('teacher'))
	);
	// var {roomID} = useParams();
	// if (roomID === undefined) {
	const {roomID} = useParams();
	useEffect(() => {
		const teachers = JSON.parse(localStorage.getItem('teacher'));

		if (teachers !== 'undefined' && teachers !== null) {
			setIsTeacher(true);
		}
	}, [authData, isTeacher]);

	// }
	let myMeeting = async (element: HTMLDivElement) => {
		// generate Kit Token
		if (isTeacher === false) {
			const appID = 2052101334;
			const serverSecret = 'eb701946f832ff2a005c3eb8438ef61f';
			const randUser = Math.floor(Math.random() * 1000);
			const user = randUser.toString();
			const name = 'student-' + user;
			const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
				appID,
				serverSecret,
				roomID,
				user,
				name
			);
			// Create instance object from Kit Token.
			const zp = ZegoUIKitPrebuilt.create(kitToken);
			// start the call
			zp.addPlugins({ZegoSuperBoardManager});
			zp.joinRoom({
				container: element,
				sharedLinks: [
					{
						name: 'Copy link',
						url:
							window.location.protocol +
							'//' +
							window.location.host +
							window.location.pathname,
					},
				],
				onLeaveRoom: () => {
					isTeacher
						? (window.location.href = '/teacher-dashboard')
						: (window.location.href = '/student-dashboard');
				},
				scenario: {
					mode: ZegoUIKitPrebuilt.GroupCall,
				},
				whiteboardConfig: {
					showAddImageButton: true,
				},
			});
		}
		if (isTeacher === true) {
			const appID = 2052101334;
			const serverSecret = 'eb701946f832ff2a005c3eb8438ef61f';
			const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
				appID,
				serverSecret,
				roomID,
				teacher.id,
				teacher.name
			);
			// Create instance object from Kit Token.
			const zp = ZegoUIKitPrebuilt.create(kitToken);
			// start the call
			zp.joinRoom({
				container: element,
				sharedLinks: [
					{
						name: 'Copy link',
						url:
							window.location.protocol +
							'//' +
							window.location.host +
							window.location.pathname,
					},
				],
				scenario: {
					mode: ZegoUIKitPrebuilt.GroupCall,
				},
			});
		}
		if (
			authData === null &&
			(authData === undefined || authData === 'undefined') &&
			teacher === null &&
			(teacher === undefined || teacher === 'undefined') &&
			userId === null &&
			(userId === undefined || userId === 'undefined')
		) {
			window.location.href = '/login';
		}
	};
	return (
		<div class="take_main_wrapper">
			{isTeacher ? (
				<div className="col-lg-2 col-md-3 col-sm-3">
					<div className="take_logo">
						<a href="/teacher-dashboard">
							<img
								src={logonew}
								style={{
									width: '5%',
									height: '5%',
									cursor: 'pointer',
								}}
								class="img-fluid"
								alt=""
							/>
						</a>
					</div>
				</div>
			) : (
				<div className="col-lg-2 col-md-3 col-sm-3">
					<div className="take_logo">
						<a href="/student-dashboard">
							<img
								src={logonew}
								style={{
									width: '5%',
									height: '5%',
									cursor: 'pointer',
								}}
								class="img-fluid"
								alt=""
							/>
						</a>
					</div>
				</div>
			)}
			<div ref={myMeeting} style={{width: '100%', height: '95vh'}}></div>
		</div>
	);
};

export default TeacherVideoCall;
