import React, {useEffect, useState} from 'react'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Api from '../../services/Api';
import {Footer} from '../../components';
import {Header} from '../../components';
import {useHistory} from 'react-router-dom';

const Instruments = (props) => {
	const [instrumentData, setInstrumentData] = useState([]);
	const history = useHistory();
	useEffect(() => {
		Api.getInstrument().then((response) => {
			//  console.log(response.data.data);
			setInstrumentData(response.data.data);
		});
	}, [setInstrumentData]);
	const onBlogClick = (slug) => {
		history.push(`/instruments-${slug}`);
	};
	return (
		<>
			<Header />

			<div class="take_section take_instrument_wrapper">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="take_heading_wrapper text-center">
								<h2 className="h2">Courses we provide!</h2>
								<p>
									We provide
									<span
										className="take_orange"
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
										}}>
										{' '}
										CERTIFICATIONS
									</span>{' '}
									for all the instruments that we teach.
									<br />
									We have a team of highly qualified and experienced teachers
									who are well versed with the syllabus and exam pattern
									required for certifications.
								</p>
							</div>

							<div
								class="row"
								style={{
									cursor: 'pointer',
								}}>
								{instrumentData.map((iitem, key) => {
									return (
										<div
											class="col-lg-3 col-md-3 col-sm-6 col-xs-6"
											onClick={() => {
												onBlogClick(iitem.id);
											}}>
											<div class="take_instrument_box text-center">
												<span class="take_icon">
													<img src={iitem.img} class="img-fluid" alt="" />
												</span>
												<h2 className="h2">{iitem.iname}</h2>
												<h5>{iitem.number_of_teacher} Teachers</h5>
											</div>
										</div>
									);
								})}
							</div>
							<div className="take_section take_certificate_wrapper">
								<div className="container">
									<div className="row">
										<div className="col-lg-5 col-md-12">
											<div className="take_heading_wrapper">
												<h2 className="h2">
													Prepare confidently for{' '}
													<span className="take_orange">
														Global Certification Courses
													</span>
												</h2>
												<p>
													We prepare you for various certification courses like
													Trinity, ABRSM and Rockschool
												</p>
											</div>
										</div>
										<div className="col-lg-7 col-md-12">
											<div className="take_certi_images text-center">
												<img
													src="assets/images/certi1.png"
													className="img-fluid take_c1"
													alt=""
												/>
												<img
													src="assets/images/certi2.png"
													alt=""
													className="img-fluid take_c2"
												/>
												<img
													src="assets/images/certi5.png"
													alt=""
													className="img-fluid take_c3"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Instruments;
